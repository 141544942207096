body {
    background-color: hsl(136, 75%, 80%)
}

/* CSS Selectors containing ID (#) */ 

#completedrow:nth-child(even) {background-color: #939fe4;} /* completedRow coloring even rows*/ 

#completedrow:nth-child(odd) {background-color: #d4ca6c;} /* completedRow coloring odd rows*/ 

#headingRow {background-color: #5166df; } /* seperating so that the heading row will be darker shade */ 

/* CSS Selectors containing attribute */

h2[att="aboutHeader"] {
    text-decoration: underline;
}

/* CSS Selectors expressing ancestor/descendant relationship */ 

body p {
    font-style: italic;
}

/* CSS Selectors expressing immediate parent/child relationship such as div > p */

div > h1, p {
    text-align: center;
}

/* CSS Selectors expressing any sibling relationship */

h1 ~ p {
    color: brown;
}

/* CSS Selectors expressing immediate sibling relationships */

h1 + p {
    color: orangered;
}

/* CSS Selectors 4 differetn psuedo-classes of preference */ 

h2:first-of-type {
    color: gray;
}

div p:last-of-type {
    background-color: black;
}

h1:hover {
    color: blue;
}

h2 table tr:first-child {
    font-weight: bold;
}

/* 5-Toke CSS Selectors combing any of the above elements */

h2:nth-of-type(2) ol > li ul:last-of-type {
    color: red;
}








